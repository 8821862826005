import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';

const TechLinks = [
    {
        title: "Quotation Generation",
        to: "/technical/quotation-generation-framework",
        icon: <SellOutlinedIcon />,
    }


    
];

export default TechLinks;
